<template>
  <div>
    <a-card>
      <!-- 付款 -->
      <template v-if="type === 1">
        <template v-for="(item, index)  in bizParams.applyAccounts" :key="index">
          <a-descriptions :title="bizParams.applyAccounts?.length === 1 ? `付款对象-线下` : `付款对象-线下`">
            <a-descriptions-item label="款项">{{ item.projectName }}</a-descriptions-item>
            <a-descriptions-item label="收款方式">{{ item.mod }}</a-descriptions-item>
            <a-descriptions-item label="金额">{{ item.amt }}</a-descriptions-item>
            <a-descriptions-item label="账号">{{ item.accountNum }}</a-descriptions-item>
            <a-descriptions-item label="户名">{{ item.accountName }}</a-descriptions-item>
            <a-descriptions-item label="开户行">{{ item.accountBank }}</a-descriptions-item>
            <a-descriptions-item label="收款码">
              <a-image v-if="item.payeeFileId" :width="20" :src="item.payeeFileUrl" />
            </a-descriptions-item>
            <a-descriptions-item label="收款方信息">
              {{ item.payeeInfo }}
            </a-descriptions-item>
            <a-descriptions-item label="付款备注" v-if="index<=0">
              {{ bizParams.remark }}
            </a-descriptions-item>
          </a-descriptions>
        </template>
      </template>
      <template v-if="type === 0&&bizParams.collectionAccount">
        <!-- 收款 -->
        <a-descriptions title="付款对象" :extra="bizParams.collectionAccount.customerName" :column="2">
          <a-descriptions-item label="付款人">{{ bizParams.collectionAccount.payer }}</a-descriptions-item>
          <a-descriptions-item label="付款账户">{{ bizParams.collectionAccount.payWay }}</a-descriptions-item>
          <a-descriptions-item label="金额">{{ bizParams.collectionAccount.amt }}</a-descriptions-item>
          <a-descriptions-item label="付款时间">{{ bizParams.collectionAccount.paymentTime }}</a-descriptions-item>
          <a-descriptions-item label="付款截图" :span="2">
            <a-image v-if="bizParams.collectionAccount.paymentfileId" :width="50" :src="bizParams.collectionAccount.paymentfileUrl" />
          </a-descriptions-item>
          <a-descriptions-item label="备注" :span="2">
            {{ bizParams.collectionAccount.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </template>
      <a-table v-if="bizParams.orderSettlementIds" :data-source="bizParams.orderSettlementIds" :row-key="record => record.id"  :style="{ marginTop: '24px' }" bordered>
        <template #title><b>订单明细</b></template>
        <a-table-column title="订单编号" data-index="orderId" />
        <a-table-column title="订单金额" data-index="contractAmt" />
        <a-table-column title="结算类型" data-index="settlementMode.label" />
        <a-table-column title="本次结算金额" data-index="nowSettledAmt" />
        <a-table-column :width="120" title="小车信息" data-index="vehicleList">
          <template #default="{ record }">
            <div v-for="(item, index) in record.vehicleList" :key="index">
              <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{ item.vinNo }} 金额：{{ item.transportFee }}元</a-tag><br />
            </div>
          </template>
        </a-table-column>
        <a-table-column title="起始地" data-index="startAddress" />
        <a-table-column title="目的地" data-index="endAddress" />
        <a-table-column title="推送时间" data-index="startTime" />
        <a-table-column title="结算类型" data-index="orderSettlement.label" />
      </a-table>
      <a-table  :scroll="{x:1300}" v-if="bizParams.transSettlementIds" :data-source="bizParams.transSettlementIds" :columns="transColumns" :row-key="record => record.id"  :style="{ marginTop: '24px' }" bordered>
        <template #title><b>运单明细</b></template>
        <template #transId="{ record }">
          <a-typography-text v-if="record.transId != null" :copyable="{ tooltips: false }" :content="record.transId" />
        </template>
        <template #transName="{ record }">
          <span @click="getVehicleDetails(record.transId)">{{ record.transName }}</span>
        </template>
        <template #vehicleLists="{ record }">
        <div v-for="(item, index) in record.vehicleList" :key="index">
          <a-tag color="#365DD2" style="cursor: pointer;">车架号：{{ item.vinNo }} 金额：{{ item.transportFee }}元</a-tag><br />
        </div>
      </template>
        <template #settlementMode="{ record }">
          {{ record.settlementMode?.label }}<br /><a-tag v-if="record.settlementMode?.value === 3" color="red">{{ record.exceptionType?.label }}</a-tag>
        </template>
        <template #address="{ record }">
          <span>{{ record.startAddress }}</span><br />
          <span>{{ record.endAddress }}</span>
        </template>
      </a-table>
    </a-card>
    <!-- <a-modal v-model:visible="transDetailsVisible" width="80%" title="运单详情" footer="" destroy-on-close>
    <transDetails :transId="transId" />
  </a-modal> -->
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  bizParams: {
    type: Object,
    default: {}
  },
  type: {
    type: Number,
    default: 0
  }
})
const transColumns = ref([
  { title: '运单编号', dataIndex: 'transId', key: 'transId', slots: { customRender: 'transId' } },
  { title: '类型', dataIndex: 'settlementMode', key: 'settlementMode', slots: { customRender: 'settlementMode' } },
  { title: '承运商', dataIndex: 'carrierName', key: 'carrierName' },
  { title: '车牌号/司机', dataIndex: 'carNo', key: 'carNo', slots: { customRender: 'carNo' } },
  { title: '司机姓名', dataIndex: 'driverName', key: 'driverName',width:90 },
  { title: '起始地-目的地', dataIndex: 'address', key: 'address', slots: { customRender: 'address' } },
  { title: '小车信息', dataIndex: 'vehicleLists', key: 'vehicleLists', slots: { customRender: 'vehicleLists' },width:220 },
  { title: '申请金额', dataIndex: 'nowSettledAmt', key: 'nowSettledAmt' },
  { title: '出发时间', dataIndex: 'startTime', key: 'startTime' },
  { title: '到达时间', dataIndex: 'endTime', key: 'endTime' }
])
</script>

<style lang="less" scoped>

</style>