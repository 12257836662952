<template>
  <div>
    <!-- 标题 -->
    <a-page-header class="ant-page-header" :title="`${instance.sponsor}提交的（${flowType.label}）结算申请`" :ghost="false">
      <a-descriptions>
        <a-descriptions-item label="实例编号">
          <a-typography-paragraph copyable>
            {{ instance.instanceId }}
          </a-typography-paragraph>
        </a-descriptions-item>
        <a-descriptions-item label="申请部门">{{ instance.orgName }}</a-descriptions-item>
        <a-descriptions-item label="其他图片">
          <a-image :width="50" :src="item" v-for=" (item, index) in instance.fileUrls" :key="instance.fileIds[index]" />
          <div class="pos-abs" style="right: 60px;" v-if="type ===  1">
            <a-button type="primary" shape="round" @click="remarkTask">标记</a-button>
          </div>
        </a-descriptions-item>

      </a-descriptions>
    </a-page-header>

    <!-- 流程 -->
    <a-row :gutter="[16, 16]" :style="{ marginTop: '4px', marginBottom: '40px' }">
      <a-col :xs="24" :sm="24" :md="16" :lg="18">
        <!-- 组件 -->
        <component :is="assemblyType" :bizParams="bizParams" :type="instance.type?.value" />
      </a-col>
      <a-col :xs="24" :sm="24" :md="8" :lg="6">
        <a-card title="流程">
          <a-steps direction="vertical">
            <template v-for="(item, index) in taskList" :key="index">
              <a-step :title="item.nodeName">
                <template #icon>
                  <a-avatar shape="square" src="https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar">
                    <template #icon>
                      <CheckCircleFilled />
                    </template>
                  </a-avatar>
                </template>
                <template #subTitle>
                  <span style="float:right">{{ item.nodeHandleTime }}</span>
                </template>
                <template #description>
                  {{ item.handler }}
                  <template v-if="index > 0">
                    <template v-if="item.approveType">
                      <span>({{ item.approveType.label }})</span>
                    </template>
                    <template v-else-if="!item.approveType && item.current.value">
                      （审核中）
                    </template>
                    <template v-else>
                      （待审核）
                    </template>
                  </template>
                  <a-card v-if="item.approveRemark">
                    {{ item.approveRemark }}
                  </a-card>
                  <div v-if="item.mimeType === 'pdf'">
                    <a @click="lookImg(item.fileId)">查看</a>
                  </div>
                  <div v-else><a-image v-if="item.fileId" :width="50" :src="item.fileUrl" /></div>
                </template>
              </a-step>
            </template>
          </a-steps>
        </a-card>
      </a-col>
    </a-row>

    <div class="btn">
      <a-space>
        <a-button type="primary" shape="round" @click="copyFlow" ghost>抄送</a-button>
        <a-button type="primary" shape="round"  @click="agreeInvoice(9)" ghost>评论</a-button>
        <a-button v-if="type === 1&&instance.flowInstanceStatus?.value === 1" type="primary" shape="round" @click="agreeInvoice(5)" ghost>拒绝</a-button>
        <span>
          <a-button v-if="type === 1&&instance.flowInstanceStatus?.value === 1" type="primary" shape="round" @click="agreeInvoice(1)">同意</a-button>
        </span>
      </a-space>
    </div>
    <!-- 同意 -->
    <a-modal v-model:visible="agreeVisible" title="审核流程" destroyOnClose width="60%" @ok="agreeOk" :confirmLoading="confirmLoading">
      <div style="min-height: 500px;overflow: auto;">
        <a-textarea rows="4" v-model:value="executeParams.flowInstanceParams.remark" :placeholder="placeholder" />
        <ly-uploadList class="m-t1" ref="uploadListRef"></ly-uploadList>
      </div>
    </a-modal>

    <!-- 抄送 -->
    <a-modal v-model:visible="copyVisible" title="抄送" footer='' destroyOnClose>
      <ly-formList @searchMore="searchCopy" ref="formListRef" @cancel="copyVisible = false" :fromList="formList" @getVal="copyVal"></ly-formList>
    </a-modal>

    <!-- 标记 -->
    <a-modal v-model:visible="signVisible" title="标记" footer='' destroyOnClose>
      <ly-formList  ref="formListRef"  :fromList="signFormList" @getVal="signVal" @cancel="signVisible = false"></ly-formList>
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import { employeeList } from '@/api/utils'
import Reconciliation from './reconciliation'
import { instanceInfo, copyPeople, execute, taskRemark } from '@/api/flow/flow'
import { formList } from './flowDetails'
import { debounce } from '@/utils/util'
import { message } from 'ant-design-vue'
const props = defineProps({
  type: { //type0为申请,为1待办，为2已办为3抄送
    type: Number,
    default: 0
  },
  flowId: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['processingStatus'])
const confirmLoading = ref(false)
const placeholder = ref('')
const uploadListRef = ref(null)
const instance = ref({})
const flowType = ref({})
const taskList = ref([])
const bizParams = ref({})
const assemblyType = ref('')
const copyVisible = ref(false)
const agreeVisible = ref(false)
const resultType = ref(0)
const signVisible = ref(false)
const formListRef = ref(null)
const executeParams = reactive({
  flowInstanceId: '',
  flowInstancTaskId: '',
  approveType: {},
  bizParams: {}, // 业务参数
  flowInstanceParams: {}, // 流程参数
})

// 标记
const remarkTask = () => {
  signVisible.value = true
}
const signVal = (e) => {
  formListRef.value.fileLoading = true
 e.flowInstanceTaskId = executeParams.flowInstancTaskId
 taskRemark(e).then( res => {
  if(res.code !== 10000) return
  signVisible.value = false
  emit('processingStatus')
  message.success(res.msg)
 }).finally(() => {
  if(formListRef.value){
    formListRef.value.fileLoading = false
  }
 })
}
const reqInstanceInfo = (id) => {
  instanceInfo(id).then(res => {
    if (res.code !== 10000) return
    instance.value = res.data.instance
    flowType.value = res.data.flowType
    taskList.value = res.data.taskList
    bizParams.value = res.data.bizParams
    executeParams.flowInstanceId = instance.value.instanceId
    executeParams.flowInstancTaskId = instance.value.flowInstancTaskId
    console.log(res);
  })
}
// 同意
// 同意为1拒绝5评论9
const agreeInvoice = (type) => {
  executeParams.flowInstanceParams = {}
  resultType.value = type
  if (type === 1) {
    placeholder.value = '请输入同意内容'
  } else if (type === 5) {
    placeholder.value = '请输入拒绝内容'
  } else if (type === 9) {
    placeholder.value = '请输入评论'
  }
  agreeVisible.value = true
}
const agreeOk = () => {
  let ids = uploadListRef.value.state.fileIds.map(item => item.id)
  executeParams.flowInstanceParams.fileId = ids[0]
  executeParams.approveType = resultType.value
  requireAPI()
}
//抄送
const copyVal = (e) => {
  formListRef.value.fileLoading = true
  let msg = {
    instanceId: instance.value.instanceId,
  }
  Object.assign(msg, e)
  copyPeople(msg).then(res => {
    if (res.code !== 10000) return
    message.success(res.msg)
    formList.value[0].opt = []
    copyVisible.value = false
  }).finally(() => {
    if(formListRef.value){
      formListRef.value.fileLoading = false
    }
  })
}
const copyFlow = () => {
  copyVisible.value = true
}
const state = reactive({
  name: ''
})
//抄送搜索
const searchCopy = (e) => {
  state.name = e
  debounce(state, reqPageList, 1000)
}
const reqPageList = () => {
  employeeList({ name: state.name }).then(res => {
    if (res.code === 10000) {
      let list = []
      res.data.forEach(item => {
        list.push({
          label: item.name,
          value: item.id
        })
      })
      formList.value[0].opt = list
    } else {
      formList.value[0].opt = []
    }
  })
}
onMounted(() => {
  reqInstanceInfo(props.flowId)
  assemblyType.value = Reconciliation
})
const requireAPI = (msg) => {
  confirmLoading.value = true
  if (msg) {
    executeParams.bizParams = msg
  }
  execute(executeParams).then(res => {
    if (res.code === 10000) {
      message.success(res.msg)
      agreeVisible.value = false
      reqInstanceInfo(props.flowId)
      if(resultType.value !== 9){
        emit('processingStatus')
      }
    }
  }).finally(() => {
    confirmLoading.value = false
  })
}
const signFormList = ref([
  {
    label: '标记内容',
    name: 'remark',
    type: 'textarea',
    placeholder: '请输入你想要的标记',
    value: '',
    prop: 'remark',
    width: '100%',
  },
])
</script>

<style lang="less" scoped>
.btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
  z-index: 1;
}
:deep(.ant-steps-item-title){
  padding: 0;
}
</style>